<template>
<navBar :name="`${name_store}流水明细`" :list="navlist"></navBar>
<div class="top-box">
  <div class="item">
    <div>{{statisForm.obj.total}}</div>
    <div>{{ topDateType }}总收:</div>
  </div>
  <div class="item">
    <div style="color: #3D7EFF;">{{statisForm.obj.charge}}</div>
    <div>{{ topDateType }}总手续费:</div>
  </div>
  <div class="item">
    <div style="color:#666666">{{statisForm.obj.count}}</div>
    <div>{{ topDateType }}总笔数:</div>
  </div>
  <div class="item">
    <div style="color: #02CE80;">{{statisForm.obj.height}}</div>
    <div>{{ topDateType }}单笔最高:</div>
  </div>
  <div class="item item-date">
    <el-date-picker name="date" style="width: 500px;" id="date" @change="topDateChange" v-model="topDate" type="daterange" value-format="YYYY-MM-DD"></el-date-picker>
  </div>
</div>
<div class="waterDetail">
  <vxe-grid ref="xGrid" class="my-grid66" v-bind="gridOptions" @checkbox-change="checkboxChangeEvent" @checkbox-all="checkboxChangeEvent" height="auto" border="inner">
    <!--使用 form 插槽-->
    <!-- <template #form>
            <vxe-form :data="demo1.formData" @submit="searchEvent">
              <vxe-form-item title="名称" field="name">
                <template #default="{ data }">
                  <vxe-input v-model="data.name" placeholder="请输入名称" clearable></vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item title="昵称" field="nickname">
                <template #default="{ data }">
                  <vxe-input v-model="data.nickname" placeholder="请输入昵称" clearable></vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item>
                <template #default>
                  <vxe-button status="primary">查询</vxe-button>
                </template>
              </vxe-form-item>
            </vxe-form>
      </template>-->

    <!--自定义插槽 toolbar buttons 插槽-->
    <template #toolbar_buttons>
      <div class="topForm">
        <el-select class="right" @change="getList" clearable v-model="searchForm.pay_type" placeholder="请选择支付方式">
          <el-option label="主扫" :value="1"></el-option>
          <el-option label="被扫" :value="2"></el-option>
          <el-option label="店铺码" :value="3"></el-option>
        </el-select>
        <el-date-picker placeholder type="datetimerange" v-model="date" value-format="YYYY-MM-DD HH:mm:ss" :default-time="defaultTime" @change="dateChange" class="right"></el-date-picker>
        <el-select placeholder="时间段选择" @change="dateTypeChange" v-model="searchForm.date_type" class="right" style="margin-left:25px" clearable>
          <el-option label="今日" value="today"></el-option>
          <el-option label="昨日" value="yesterday"></el-option>
          <el-option label="近一个月" value="nearlyMonth"></el-option>
        </el-select>
        <myButton status="primary" class="right" @click="getList" title="搜索">
          <template #preImage>
            <img src="@/assets/search.png" alt />
          </template>
        </myButton>
        <myButton status="primary" class="right" @click="printEvent1" title="打印">
          <template #preImage>
            <img class="icon" src="@/assets/dy.png" alt />
          </template>
        </myButton>
        <myButton status="primary" class="right" @click="printSelectEvent1" title="打印勾选">
          <template #preImage>
            <img class="icon" src="@/assets/dy.png" alt />
          </template>
        </myButton>
        <myButton status="primary" class="right" @click="exportDataEvent" title="导出">
          <template #preImage>
            <img class="icon" src="@/assets/down.png" alt />
          </template>
        </myButton>
        <myButton status="primary" class="right" @click="exportSelectEvent" title="导出选中">
          <template #preImage>
            <img class="icon" src="@/assets/down.png" alt />
          </template>
        </myButton>
        <myButton status="primary" class="right" @click="exportAllEvent" title="导出全部">
          <template #preImage>
            <img class="icon" src="@/assets/down.png" alt />
          </template>
        </myButton>
      </div>

    </template>

    <!--自定义插槽 toolbar tools 插槽-->
    <template #toolbar_tools>
      <!-- <div class="head">
          <span>流水明细--</span>
          <span>{{ name_store }}</span>
        </div>-->
    </template>

    <!--使用 top 插槽-->

    <!--自定义插槽-->
    <!-- <template #name_header>
            <div class="first-col">
              <div class="first-col-top">名称</div>
              <div class="first-col-bottom">类型</div>
            </div>
            <div>店铺ID</div>
      </template>-->

    <template #num_default="{ row, rowIndex }">
      <template v-if="rowIndex === 2">
        <vxe-switch v-model="row.flag"></vxe-switch>
      </template>
      <template v-else-if="rowIndex === 3">
        <vxe-switch v-model="row.flag" open-label="开" close-label="关"></vxe-switch>
      </template>
      <template v-else>
        <span>{{ row.num1 }}</span>
      </template>
    </template>
    <template #num_footer="{ items, _columnIndex }">
      <span style="color: red">合计：{{ items[_columnIndex] }}</span>
    </template>

    <template #num1_default="{ row }">
      <span>￥{{ row.num1 }}元</span>
    </template>

    <template #num1_header="{ column }">
      <span>
        <i>@</i>
        <span style="color: red;" @click="headerClickEvent">{{ column.title }}</span>
      </span>
    </template>

    <template #num1_footer="{ items, _columnIndex }">
      <span>
        <vxe-button status="primary" size="mini">按钮</vxe-button>
        <span>累计：{{ items[_columnIndex] }}</span>
      </span>
    </template>

    <template #num1_filter="{ column, $panel }">
      <div>
        <div v-for="(option, index) in column.filters" :key="index">
          <input type="type" v-model="option.data" @input="changeFilterEvent($event, option, $panel)" />
        </div>
      </div>
    </template>

    <template #num1_edit="{ row }">
      <input type="number" class="my-input" v-model="row.num1" />
    </template>

    <template #img1_default="{ row }">
      <img v-if="row.img1" :src="row.img1" style="width: 100px;" />
      <span v-else>无</span>
    </template>

    <!--自定义插槽 pager 插槽-->
    <template #pager>

      <div class="public-page">
        <el-pagination :total="total" next-text="下一页" prev-text="上一页" @current-change="getList" layout="total,prev,pager,next" :page-size="searchForm.pageSize"></el-pagination>
      </div>

    </template>
  </vxe-grid>

  <vxe-modal v-model="demo1.showDetails" title="查看详情" width="800" height="400" resize>
    <template #default>
      <div v-if="demo1.selectRow" v-html="demo1.selectRow.html3"></div>
    </template>
  </vxe-modal>
</div>
</template>
<script >
import {
  defineComponent,
  reactive,
  ref,
  inject,
  nextTick,
  onMounted
} from 'vue';
import {
  useRoute
} from 'vue-router';
import {
  VXETable
} from 'vxe-table'

export default defineComponent({
  setup() {
    const route = new useRoute()
    let navlist = []
    const xGrid = ref({})
    const vm = inject("plugin");

    const demo1 = reactive({
      searchVal1: '',
      searchVal2: '',
      showDetails: false,
      selectRow: null,
      isAllChecked: false,
      isIndeterminate: false,
      selectRecords: [],
      formData: {
        name: '',
        nickname: '',
        sex: ''
      },
      tablePage: {
        totalResult: 8,
        currentPage: 1,
        pageSize: 10
      }
    })
    onMounted(() => {
      getStatistics()
    })
    // 获取统计数据
    let statisForm = reactive({
      obj: {
        total: '-',
        charge: '-', //手续费
        count: '-',
        height: '-'
      }
    })
    let statisSearchaForm = reactive({
      end_at: '',
      start_at: '',
      store_no: ''
    })
    statisSearchaForm.store_no = route.query.id
    const getStatistics = () => {
      vm.HttpPost('/admin/summary/store  ', statisSearchaForm)
        .then(res => {
          if (res.data) {
            statisForm.obj = res.data
          } else {
            for (let i in statisForm) {
              statisForm[i] = '-'
            }
          }
        })
    }
    // 头部
    // 时间筛选
    let topDate = ref('历史');
    let topDateType = ref('历史')
    nextTick(() => {

      let datePicker = document.querySelector('.el-range-separator');
      let dateInput = document.querySelector('.el-range-input');
      dateInput.style.display = 'none'
      datePicker.innerHTML = '历史'
    })
    const topDateChange = (e) => {
      if (e) {
        statisSearchaForm.start_at = e[0];
        statisSearchaForm.end_at = e[1];
        nextTick(() => {
          let dateInput = document.querySelector('.el-range-input');
          dateInput.style.display = 'block'
          let datePicker = document.querySelector('.el-range-separator');
          datePicker.innerHTML = '-'
        })
      } else {
        statisSearchaForm.start_at = '';
        statisSearchaForm.end_at = '';
        nextTick(() => {
          let datePicker = document.querySelector('.el-range-separator');
          let dateInput = document.querySelector('.el-range-input');
          dateInput.style.display = 'none'
          datePicker.innerHTML = '历史'
        })
      }
      getStatistics()
    }
    const gridOptions = reactive({
      border: true,
      showFooter: true,
      height: 600,
      columnConfig: {
        resizable: true
      },
      editConfig: {
        trigger: 'click',
        mode: 'cell',
        icon: 'fa fa-pencil-square-o'
      },
      data: [],
      toolbarConfig: {
        custom: true,
        slots: {
          buttons: 'toolbar_buttons',
          tools: 'toolbar_tools'
        }
      },
      columns: [{
          type: 'checkbox',
          width: 80,
          title: '选择'
        },
        // { field: 'name', title: 'Name', resizable: false, slots: { header: 'name_header' } },
        {
          field: 'uni_order_no',
          title: '订单号'
        },
        {
          field: 'amount',
          title: '订单金额'
        },
        {
          field: 'hsb_charge',
          title: '手续费'
        },
        {
          field: 'payWAY',
          title: '支付方式',
          slots: {
            default: ({
              row
            }) => {
              return [ <
                span > {
                  row.pay_type == 1 ? '主扫' : row.pay_type == 2 ? '被扫' : '店铺码'
                } < /span>
              ]
            }
          }
        },
        {
          field: 'remark',
          title: '备注'
        },
        {
          field: 'create_at',
          title: '订单时间'
        },
      ],
      footerMethod({
        columns,
        data
      }) {
        return [
          columns.map((column, index) => {
            if (index === 0) {
              return '当前页合计'
            } else if (['amount', 'hsb_charge'].includes(column.property)) {
              return calcSum(data, column.property)
            }
            return '/'
          })
        ]
      },

    })
    // 合计
    const calcSum = (value, type) => {
      if (!value || !Array.isArray(value)) {
        return '/'
      }

      let total = value.reduce((totals, item) => {
        totals += Number(item[type]);
        return totals
      }, 0)
      return total.toFixed(2)
    }
    const searchEvent = () => {
      VXETable.modal.alert('查询')
    }

    const headerClickEvent = () => {
      VXETable.modal.alert('头部点击事件')
    }

    const changeFilterEvent = (event, option, $panel) => {
      $panel.changeOption(event, !!option.data, option)
    }

    const showDetailEvent = (row) => {
      demo1.selectRow = row
      demo1.showDetails = true
    }

    const checkboxChangeEvent = () => {
      const $grid = xGrid.value
      demo1.isAllChecked = $grid.isAllCheckboxChecked()
      demo1.isIndeterminate = $grid.isAllCheckboxIndeterminate()
      demo1.selectRecords = $grid.getCheckboxRecords()
    }

    const changeAllEvent = () => {
      const $grid = xGrid.value
      $grid.setAllCheckboxRow(demo1.isAllChecked)
      demo1.selectRecords = $grid.getCheckboxRecords()
    }
    const name_store = route.query.name;

    let total = ref(0)
    const searchForm = reactive({
      "page": 1,
      "pageSize": 20,
      "store_no": "",
      "pay_type": "",
      "start_at": "",
      "end_at": "",
      "uni_order_no": "",
      "pay_no": "",
      "remark": "",
      "date_type": "today"
    })

    searchForm.date_type = route.query.date_type;
    const getList = (e = 1) => {
      searchForm.store_no = route.query.id;
      searchForm.page = e
      vm.HttpPost('/admin/financial/list', searchForm)
        .then(res => {
          if (res.data) {
            gridOptions.data = res.data.list;
            total.value = res.data.total;
            allSum.value = res.data.amountTotal;
            sxfSum.value = res.data.hsbChargeAmount
          }
        })
    }

    // 打印
    // 打印样式
    const printStyle = `
              .sum-box{
      font-size:18px;
      margin-top:20px
    }
    .sum-box .num{
      color: #f84646;
      margin: 0 30px 0 10px;
      font-size: 20px;
    }`
    //顶部内容
    // const topHtml = `<h1 class="title">${name_store}流水明细</h1>`
    let allSum = ref(0) //总金额
    let sxfSum = ref(0)

    let printDateType = ref('今日')
    printDateType.value = route.query.date_type == 'today' ? '今日' : route.query.date_type == 'yesterday' ? '昨日' : '近一个月'
    const printEvent1 = () => {
      let bottomHtml = ` <div class="footer-box">
          <div class="sum-box">
          <span>总合计:</span>
          <span class="num">${allSum.value}</span>
          <span>手续费总合计:</span>
          <span class="num">${sxfSum.value}</span>
        </div>`
      let printTime = date.value ? `${searchForm.start_at}~${searchForm.end_at}` : printDateType.value;
      let topHtml = `<div style='display:flex;justify-content:space-between;padding:20px 20px;font-weight:bolder;font-size:25px'><span >${name_store}流水明细</span><span>${printTime}</span></div>`
      let $table = xGrid.value
      $table.print({
        style: printStyle,
        columns: [{
            type: 'uni_order_no'
          },
          {
            field: 'amount'
          },
          {
            field: 'hsb_charge'
          },
          {
            field: 'payWAY'
          },
          {
            field: 'remark'
          },
          {
            field: 'create_at'
          }
        ],
        beforePrintMethod: ({
          content
        }) => {
          // 拦截打印之前，返回自定义的 html 内容
          return topHtml + content + bottomHtml
        }
      })
    }
    // 打印勾选行
    const printSelectEvent1 = () => {
      let printTime = date.value ? `${searchForm.start_at}~${searchForm.end_at}` : printDateType.value;
      let topHtml = `<div style='display:flex;justify-content:space-between;padding:20px 20px;font-weight:bolder;font-size:25px'><span >${name_store}流水明细</span><span>${printTime}</span></div>`
      let bottomHtml = ` <div class="footer-box">
          <div class="sum-box">
          <span>总合计:</span>
          <span class="num">${allSum.value}</span>
          <span>手续费总合计:</span>
          <span class="num">${sxfSum.value}</span>
        </div>`
      let $table = xGrid.value
      $table.print({
        sheetName: '打印勾选行',
        style: printStyle,
        mode: 'selected',
        columns: [{
            type: 'uni_order_no'
          },
          {
            field: 'amount'
          },
          {
            field: 'hsb_charge'
          },
          {
            field: 'payWAY'
          },
          {
            field: 'remark'
          },
          {
            field: 'create_at'
          }
        ],
        beforePrintMethod: ({
          content
        }) => {
          // 拦截打印之前，返回自定义的 html 内容
          return topHtml + content + bottomHtml
        }
      })
    }
    // 导出
    const exportDataEvent = () => {
      let $table = xGrid.value
      $table.exportData({
        type: 'csv',
        filename: `${name_store}流水详细${new Date().toLocaleString()}`,
        columnFilterMethod({
          column
        }) {
          return ['uni_order_no', 'amount', 'hsb_charge', 'payWAY', 'remark', 'update_at'].includes(column.property)
        }
      })
    }
    //导出选中
    const exportSelectEvent = () => {
      let $table = xGrid.value
      $table.exportData({
        data: $table.getCheckboxRecords(),
        filename: `${name_store}流水详细${new Date().toLocaleString()}`,
        columnFilterMethod({
          column
        }) {
          return ['uni_order_no', 'amount', 'hsb_charge', 'payWAY', 'remark', 'update_at'].includes(column.property)
        }
      })
    }
    //导出全部
    const exportAllEvent = () => {
      let form = {
        ...searchForm,
        is_export: 1
      };
      delete form.page;
      searchForm.page = ''
      vm.HttpPost('/admin/financial/list', {
        ...form
      }, {
          responseType: 'blob'
        })
        .then(res => {
          let blob = new Blob([res]);
          let objectUrl = URL.createObjectURL(blob);
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = objectUrl;
          link.setAttribute('download', `流水详细列表${new Date().toLocaleString()}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // 下载完成移除元素
          window.URL.revokeObjectURL(objectUrl); // 释放掉blob对象
        })
    }
    let date = ref('');
    if (route.query.start_at) {
      searchForm.start_at = route.query.start_at;
      searchForm.end_at = route.query.end_at;
      date = [ route.query.start_at, route.query.end_at ];
    }
    const dateChange = (value) => {
      if (value) {
        searchForm.start_at = value[0];
        searchForm.end_at = value[1];
        searchForm.date_type = '';
        printDateType.value = '今日'
      } else {
        searchForm.start_at = '';
        searchForm.end_at = '';
      }
      getList()
    }
    const dateTypeChange = (value) => {
      date.value = '';
      searchForm.end_at = '';
      searchForm.start_at = '';
      if (value) {
        printDateType.value = value == 'today' ? '今日' : value == 'yesterday' ? '昨日' : '近一个月'
      } else {
        printDateType.value = '今日'
      }
      getList();
    }
    const defaultTime = [
      new Date(2000, 1, 1, '00', '00', '00'),
      new Date(2000, 2, 1, 23, 59, 59),
    ] // '12:00

    getList();
    return {
      xGrid,
      demo1,
      gridOptions,
      searchEvent,
      headerClickEvent,
      changeFilterEvent,
      showDetailEvent,
      checkboxChangeEvent,
      changeAllEvent,
      printEvent1,
      printSelectEvent1,
      exportDataEvent,
      exportSelectEvent,
      exportAllEvent,
      getList,
      name_store,
      searchForm,
      total,
      date,
      dateChange,
      dateTypeChange,
      navlist,
      allSum,
      sxfSum,
      topDate,
      topDateChange,
      topDateType,
      statisForm,
      defaultTime
    }
  }
})
</script>
<style lang='scss' scoped>
.top-box {
    height: 98px;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .item {
        width: 316px;
        height: 60px;
        border-right: 1px solid #f5f5f5;
        padding-left: 98px;
        box-sizing: border-box;
        &:last-child {
            border-right: none;
        }
        img {
            width: 14px;
            height: 14px;
            margin-right: 8px;
        }
        span {
            color: #3d7eff;
            font-weight: bolder;
            font-size: 15px;
            cursor: pointer;
        }
        .el-date-picker {
            width: 10px;
        }
        /deep/ .el-input__inner {
            border: none;
            cursor: pointer;
        }
        input {
            cursor: pointer;
            font-size: 18px;
        }
        /deep/ .el-range-input {
            padding: 0 !important;
            flex-shrink: 0;
            width: 100px;
        }
        /deep/ .el-date-editor .el-range-separator {
            width: 10px !important;
            padding: 0;
            flex-shrink: 0;
            flex: 0.3;
            color: #3d7eff;
            font-weight: bolder;
            font-size: 18px;
            cursor: pointer;
        }
        div {
            &:nth-child(1) {
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
            }
            &:nth-child(2) {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
            }
        }
    }
    .item-date {
        // width: 100%;
        // background-color: red;
        padding: 0;
    }
}
.waterDetail {
    height: calc(100vh - 240px);
    padding-bottom: 50px;
    box-sizing: border-box;
    overflow: hidden;

    /deep/ .vxe-toolbar {
        align-items: flex-start;
    }
    /deep/ .vxe-header--row {
        .vxe-header--column {
            height: 48px;
            margin: 0;
            padding: 0;

            .vxe-cell {
                text-align: center;
                background-color: rgb(245, 245, 245);
                height: 48px;
                line-height: 48px;
                border-bottom: none;
            }
        }
    }
    /deep/ .vxe-body--column {
        .vxe-cell {
            text-align: center;
            background-color: #fdfdff;
        }
    }
    /deep/ .vxe-footer--row {
        .vxe-footer--column {
            background-color: #e0e2e6;
            color: rgb(73, 73, 73);
        }

        .vxe-cell {
            text-align: center;
        }
        .vxe-cell--item {
            white-space: nowrap;
        }
    }
    .topForm {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        .right {
            margin-right: 10px;
        }
    }
}
.head {
    height: 30px;
    border-bottom: 1px solid #fcf9f9;
    font-size: 18px;
}
.topForm {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    .right {
        margin-right: 25px;
    }
}
.footer-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        font-size: 14px;
        &:nth-child(2n) {
            color: rgb(248, 70, 70);
            margin: 0 30px 0 10px;
            font-size: 17px;
        }

    }
}
</style>
